import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { SEO, GeneralJumbotron, SummaryTemplate, CallToAction } from '../components';
import { MainLayout } from '../layouts';
import { MembershipPageImagesQuery } from '../shared/interfaces';

const MembershipPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<MembershipPageImagesQuery>(graphql`
        query {
            membershipBackgroundImage: file(name: {eq: "membershipBackground"}, extension: {eq: "jpg"}) {
                publicURL
            }
            membershipSummaryImage: file(name: {eq: "cardv3"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | Membership"/>
            <GeneralJumbotron
                title="Membership"
                backgroundImageURL={queryResult?.membershipBackgroundImage.publicURL}
                backgroundText="Membership"
            />
            <SummaryTemplate image={queryResult?.membershipSummaryImage.publicURL}>
                <p>
                    {`Joining PCH IPA is easy! Simply apply online at `}
                    <a href="www.pchipa.com">{`www.pchipa.com`}</a>
                    {` or call us at `}
                    <a href="tel:+18337244111">
                        <FontAwesomeIcon icon={faPhoneAlt}/>
                        {`1-833-PCH-4111`}
                    </a>
                    {` to get started.`}
                </p>
                <p>
                    {`
                        Once enrolled, PCH members can instantly access all benefits
                        of the software and begin scheduling treatment.
                    `}
                </p>
                <p>
                    {`
                        We are here for you! Your office will be fully trained on our
                        program and software system. We offer free in-office and
                        telephonic training.
                    `}
                </p>
                <p>
                    {`
                        PCH IPA is the first system of its kind, and we are so excited
                        to share this opportunity with you!
                    `}
                </p>
            </SummaryTemplate>
            {/* <CallToAction/> */}
        </MainLayout>
    );
}

export default MembershipPage;
